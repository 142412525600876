<template>
  <v-container fluid>
    <v-row
      v-for="(groupeKiosque, index) in listeKiosques"
      :key="index"
    >
      <v-col
        v-for="kiosque in groupeKiosque"
        :key="kiosque.id"
        cols="12"
        md="6"
        sm="12"
        lg="4"
      >
        <v-card class="px-5 py-3 mb-5">
          <v-avatar
            size="100"
            tile
            color="white"
            class="mx-auto v-card--material__avatar elevation-6 rounded-lg"
            style="cursor: pointer"
            @click="visiter(kiosque.id)"
          >
            <v-img :src="urlLogo(kiosque)" />
          </v-avatar>
          <v-card-title class="titre font-weight-light col-7 col-sm-10 col-md-8 col-lg-9">
            {{ kiosque.nom }}
          </v-card-title>
          <div
            :class="[{ 'hideDiv': !kiosque.planCommanditeNom }, 'row']"
          >
            <div
              :class="'col-12 white--text text-uppercase shadowIn'"
              :style="couleurCommandite(kiosque.planCommanditeCouleur)"
            >
              <v-icon
                dark
                left
              >
                mdi-shield-star-outline
              </v-icon>
              {{ kiosque.planCommanditeNom }}
            </div>
          </div>
          <v-img
            class="my-4"
            :src="urlImage(kiosque)"
            max-height="300"
            contain
            style="cursor:pointer"
            @click="visiter(kiosque.id)"
          />
          <v-btn
            class="primary"
            @click="visiter(kiosque.id)"
          >
            <v-icon left>
              mdi-play-circle-outline
            </v-icon>
            {{ $t('exposant.visiter_le_kiosque') }}
          </v-btn>
        </v-card>
      </v-col>
    </v-row>

    <div class="text-center">
      <v-dialog
        v-model="dialogAcceptation"
        width="500"
      >
        <v-card>
          <v-card-title>
            {{ $t('general.attention') }}
          </v-card-title>

          <v-card-text>
            <p>{{ $t('exposant.en_poursuivant_vous_acceptez_de_quitter_la_salle_de_conférences') }}</p>
            <p>
              {{ $t('exposant.cliquez_sur') }} <strong>{{ $t('general.accepter').toUpperCase() }} </strong>
              {{ $t('exposant.si_vous_souhaitez_visiter_le_salon_des_exposants') }}
            </p>
            <p>{{ $t('exposant.veuillez_noter_que_votre_nom_et_votre_prénom_seront_visibles') }}</p>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-checkbox
              v-model="afficherAcceptation"
              :label="$t('exposant.ne_plus_me_le_redemander')"
            />
            <v-spacer />
            <v-btn
              color="secondary"
              text
              @click="accepter(false)"
            >
              {{ $t('general.refuser') }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="accepter(true)"
            >
              {{ $t('general.accepter').toUpperCase() }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>
<style scoped>
.titre {
  position: relative;
  top: -100px;
  left: 130px;
  word-break: break-word;
  height: 80px;
  align-content: start;
}

@media screen and (max-width: 600px) {
  .titre {
    height: 130px;
  }
}

.banner {
  z-index: 1;
  width: 0;
  /* margin-right: 17%; */
  margin-right: 80px;
  margin-top: -3px;
}

.shadowIn {
  box-shadow: -1px 2px 10px 3px rgba(0, 0, 0, 0.3) inset;
}

.hideDiv {
  opacity: 0;
}
</style>

<script>
  import restApiService from '@/services/restApiService.js'

  export default {
    data: () => ({
      listeKiosques: [],
      dialogAcceptation: false,
      afficherAcceptation: false,
    }),
    computed: {},
    mounted() {
      if (!localStorage.getItem('accepterKiosque')) {
        this.dialogAcceptation = true
      }

      restApiService
        .get(`/api/webUser/infoEvenement/${this.$route.params.idEvenement}`)
        .then(result => {
          const listeAffectationsKiosques = result.data.listeAffectationsKiosques
          const listeKiosques = listeAffectationsKiosques.map(a => a.kiosque)
          this.listeKiosques = this.shuffle(listeKiosques)
          this.listeKiosques = this.listeKiosques.sort((a, b) => (a.planCommanditePoids != null ? a.planCommanditePoids : Infinity) - (b.planCommanditePoids != null ? b.planCommanditePoids : Infinity))
          this.listeKiosques = this.listeKiosques.reduce((acc, value) => {
            // Group initialization
            if (!acc[value.planCommanditePoids]) {
              acc[value.planCommanditePoids] = []
            }

            // Grouping
            acc[value.planCommanditePoids].push(value)

            return acc
          }, {})

          console.log('tesssst group: ', this.listeKiosques)
        })
        .catch(erreur => alert(erreur))
    },
    methods: {
      couleurCommandite(planCommanditeCouleur) {
        return 'background-color:' + (planCommanditeCouleur || '#E91E63;')
      },
      urlImage(kiosque) {
        return restApiService.mediaUrl(kiosque.mediaImage)
      },
      urlLogo(kiosque) {
        return restApiService.mediaUrl(kiosque.mediaLogo)
      },
      visiter(idKiosque) {
        restApiService.post(`/api/webUser/visite/kiosque/${idKiosque}`)
        this.$router.push({ path: `/orka/kiosque/${idKiosque}` })
      },
      accepter(res) {
        this.dialogAcceptation = false
        if (res === false) {
          this.$router.push('/orka/salleaccueil')
        } else {
          if (this.afficherAcceptation) {
            localStorage.setItem('accepterKiosque', true)
          }
        }
      },
      urlImageSansVideo() {
        return restApiService.mediaUrl(this.kiosque.mediaImage)
      },
      shuffle(a) {
        var j, x, i
        for (i = a.length - 1; i > 0; i--) {
          j = Math.floor(Math.random() * (i + 1))
          x = a[i]
          a[i] = a[j]
          a[j] = x
        }
        return a
      },
    },
  }
</script>
